
import { defineComponent, provide } from 'vue'
import { useStore } from 'vuex';

// Components
import Header from '@/components/native/Header.vue';
import Footer from '@/components/native/Footer.vue';
import TopNavigationBar from '@/components/navigation/TopNavigationBar.vue';

// Services
import HttpService from './services/HttpService';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import FlashcardCollectionService from '@/services/FlashcardCollectionService';
import FlashcardService from '@/services/FlashcardService';

export default defineComponent({
    components: {
        TopNavigationBar,
        Header,
        Footer
    },
    setup() {
        const debug = false;
        const apiBaseUrl = debug ? "https://localhost:7034/v1/flashcard/" : "https://api.victorkrogh.dk/v1/flashcard/"

        const store = useStore();
        const httpService: HttpService = new HttpService(apiBaseUrl);

        provide('authService', new AuthService(store, httpService));
        provide('flashcardCollectionService', new FlashcardCollectionService(store, httpService));
        provide('flashcardService', new FlashcardService(store, httpService));
        provide('userService', new UserService(store, httpService));
    }
})
