import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute bottom-0 h-9 bg-slate-800 w-full" }
const _hoisted_2 = { class: "text-center text-sm py-2 text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_Container, { class: "flex justify-center items-center h-full" }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, "Copyright © 2022 - " + _toDisplayString(_ctx.currentYear) + " | flashcard.victorkrogh.dk", 1)
      ]),
      _: 1
    })
  ]))
}