
import { computed, defineComponent } from 'vue'

import Container from '@/components/native/Container.vue';

export default defineComponent({
    name: 'NativeFooter',
    components: {
        Container
    },
    setup() {
        const currentYear = computed(() => new Date().getFullYear());

        return { currentYear }
    }
})
