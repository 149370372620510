
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        text: {
            required: true,
            type: String
        },
        url: {
            required: true,
            type: String
        }
    }
})
