import StoreState from "@/store/StoreState";
import { Store } from "vuex";

import HttpService from "./HttpService";

class AuthService {

    private store: Store<StoreState>;
    private httpService: HttpService;

    constructor(store: Store<StoreState>, httpService: HttpService) {
        this.store = store;
        this.httpService = httpService;
    }

    public async authenticate(email: string, password: string): Promise<string> {
        try {
            const response = await this.httpService.post('Auth/Authenticate', { email, password });

            this.store.dispatch('login', response);

            return response;
        }
        catch(err: any) {
            throw err as string;
        }
    }

    public async register(email: string, password: string): Promise<string> {
        try {
            const response = await this.httpService.post('Auth/Register', { email, password });

            return response;
        }
        catch(err: any) {
            console.error(err);
            throw err as string;
        }
    }

    public logout() {
        this.store.dispatch('logout');
    }

    public isAuthenticated(): boolean {
        return this.store.state.authenticated && this.store.state.currentUser != undefined;
    }

}

export default AuthService;