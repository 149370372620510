import StoreState from "@/store/StoreState";
import { Store } from "vuex";

import HttpService from "./HttpService";
import FlashcardCollection from "@/types/FlashcardCollection";

class UserService {

    private store: Store<StoreState>;
    private httpService: HttpService;

    constructor(store: Store<StoreState>, httpService: HttpService) {
        this.store = store;
        this.httpService = httpService;
    }

    public async getFlashcardCollectionsByUserId(userId: string): Promise<FlashcardCollection[]> {
        try {
            const token = this.store.state.accessToken;

            const response = await this.httpService.get(`user/${userId}/collections`, token);

            return response;
        }
        catch(err: any) {
            console.log(err);
            throw err as string;
        }
    }

}

export default UserService;