
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'NativeFormGroup',
    props: {
        htmlId: {
            required: true,
            type: String as PropType<string>
        },
        title: {
            required: true,
            type: String as PropType<string>
        },
        placeholder: {
            required: false,
            default: '',
            type: String as PropType<string>
        },
        type: {
            required: true,
            type: String as PropType<string>
        }
    }
})
