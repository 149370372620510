import axios, { AxiosError } from "axios";

class HttpService {

    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public async get(endpoint: string, jwtToken: string|undefined = undefined) {
        try {
            const response = await axios.get(`${this.baseUrl}${endpoint}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken ? `Bearer ${jwtToken}` : ''
                }
            });

            return response.data;
        }
        catch(err: any) {
            const error = err as AxiosError;
            if(error.response) {
                console.log(error.response.data.message);
                throw error.response.data.message;
            }

            return 'INTERNAL SERVER ERROR';
        }
    }

    public async post(endpoint: string, data: any, jwtToken: string|undefined = undefined): Promise<any|string> {
        try {
            const response = await axios.post(`${this.baseUrl}${endpoint}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken ? `Bearer ${jwtToken}` : ''
                }
            });

            return response.data;
        }
        catch(err: any) {
            const error = err as AxiosError;
            if(error.response) {
                console.log(error.response.data.message);
                throw error.response.data.message;
            }

            return 'INTERNAL SERVER ERROR';
        }
    }

}

export default HttpService;