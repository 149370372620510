
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'NativeButton',
    props: {
        text: {
            required: true,
            type: String as PropType<string>
        },
        type: {
            required: true,
            type: String as PropType<string>
        }
    }
})
