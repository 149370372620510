
import { computed, defineComponent, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import StoreState from '@/store/StoreState';

// Components
import Container from '@/components/native/Container.vue';
import IconButton from '@/components/native/IconButton.vue';
import TopNavigationButton from '@/components/navigation/TopNavigationButton.vue';
import TopNavigationAccountButton from '@/components/navigation/TopNavigationAccountButton.vue';

// Services
import AuthService from '@/services/AuthService';

export default defineComponent({
    name: 'TopNavigationBar',
    components: {
        Container,
        IconButton,
        TopNavigationButton,
        TopNavigationAccountButton
    },
    setup() {
        const store = useStore<StoreState>();
        const router = useRouter();

        const authService = inject('authService') as AuthService;

        const isAuthenticated = computed(() => authService.isAuthenticated());

        const user = computed(() => store.state.currentUser);

        const displayName = computed(() => store.state.currentUser?.displayName);
        const svgImage = computed(() => `https://avatars.dicebear.com/api/identicon/${store.state.currentUser?.displayName}.svg`);

        function login() {
            router.push({
                path: '/auth/login',
                force: true
            });
        }

        function logout() {
            authService.logout();

            router.push('/');
        }

        return { isAuthenticated, user, login, logout }
    }
})
