import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-center rounded-md mx-2 px-6 py-2 text-xl relative text-white hover:bg-slate-900 transition-colors duration-100" }
const _hoisted_2 = {
  key: 0,
  class: "pr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_font_awesome_icon, {
      icon: [_ctx.type, _ctx.icon]
    }, null, 8, ["icon"])
  ]))
}