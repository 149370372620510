import User from '@/types/User';
import { createStore } from 'vuex'
import StoreState from './StoreState';

import VuexPersistence from 'vuex-persist';

const vuexSession = new VuexPersistence({
    storage: window.sessionStorage
});

export default createStore<StoreState>({
    state: {
        currentUser: undefined as User|undefined,
        accessToken: undefined as string|undefined,
        authenticated: false as boolean
    },
    mutations: {
        LOGIN(state, payload) {
            state.currentUser = payload.user;
            state.accessToken = payload.token;
            state.authenticated = true;
        },
        LOGOUT(state) {
            state.currentUser = undefined;
            state.accessToken = undefined;
            state.authenticated = false;
        }
    },
    actions: {
        login(context, payload) {
            context.commit('LOGIN', payload);
        },
        logout(context) {
            context.commit('LOGOUT');
        }
    },
    getters: {
        isAuthenticated(state): boolean {
            return state.authenticated && state.currentUser !== undefined;
        }
    },
    modules: {
    },
    plugins: [vuexSession.plugin]
})
