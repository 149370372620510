import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createBlock(_component_Form, { onOnSubmitted: _ctx.handleSubmit }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormGroup, {
          htmlId: "name",
          title: "Full name",
          placeholder: "Enter full name",
          type: "text"
        }),
        _createVNode(_component_FormGroup, {
          htmlId: "email",
          title: "Email address",
          type: "text"
        }),
        _createVNode(_component_FormGroup, {
          htmlId: "password",
          title: "Password",
          type: "password"
        }),
        _createVNode(_component_Button, {
          type: "submit",
          text: "Submit"
        })
      ])
    ]),
    _: 1
  }, 8, ["onOnSubmitted"]))
}