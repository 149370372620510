import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "top-bar-button",
    "exact-active-class": "active",
    to: "/user"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.user.displayName), 1),
        _createElementVNode("img", {
          width: "24",
          class: "ml-4",
          src: _ctx.svgImage
        }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}