import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "block p-4",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.emitSubmitEvent && _ctx.emitSubmitEvent(...args)), ["prevent"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 32))
}