
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NativeIconButton',
    props: {
        type: {
            type: String,
            default: 'fas'
        },
        text: {
            type: String
        },
        icon: {
            type: String,
            required: true
        }
    }
})
